'use client'

import { forwardRef } from 'react'
import NextLink, { LinkProps as NextLinkProps } from 'next/link'
import MuiLink, { LinkProps as MuiLinkProps } from '@mui/joy/Link'

export type UILinkState = Omit<Partial<MuiLinkProps>, 'href'>
export interface UILinkBaseProps extends Omit<NextLinkProps & UILinkState, 'ownerState'> {
	ownerState?: UILinkState
}

const UILinkBase = forwardRef<HTMLAnchorElement, Partial<UILinkBaseProps>>(
	(
		{
			className,

			href,
			as,
			replace,
			scroll,
			shallow,
			passHref,
			prefetch,
			locale,
			legacyBehavior,
			onMouseEnter,
			onTouchStart,
			onClick,

			color,
			disabled,
			endDecorator,
			level,
			overlay,
			textColor,
			startDecorator,
			sx,
			underline,
			variant,
			children,
			...props
		},
		ref,
	) => {
		const isOutLink = typeof href === 'string' && href.toLocaleLowerCase().startsWith('http')
		return (
			<MuiLink
				component={isOutLink ? 'a' : NextLink}
				className={className}
				target={isOutLink ? '_blank' : undefined}
				rel={isOutLink ? 'noopener noreferrer' : undefined}
				ref={ref}
				href={href}
				replace={replace}
				overlay={overlay}
				startDecorator={startDecorator}
				endDecorator={endDecorator}
				level={level}
				variant={variant}
				color={color}
				textColor={textColor}
				underline={underline}
				disabled={disabled}
				prefetch={false}
				onClick={onClick}
				sx={sx}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
			>
				{children}
			</MuiLink>
		)
	},
)

export default UILinkBase
