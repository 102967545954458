'use client'

import { Box, styled } from '@mui/joy'
import { useRecoilValue } from 'recoil'

import { useSNSListQuery } from '@services/api/SNS/List'
import { appInterface, useIsAppControl } from '@store/isApp'

import { theme } from '@ui/style'
import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'

import UIButton from '@components/ui/UIButton'
import UIFlex from '@components/ui/UIFlex'
import UIImage from '@components/ui/UIImage'
import UILink from '@components/ui/UILink'
import UIText from '@components/ui/UIText'

export const SocialLink = styled(UILink)`
	width: ${pxToRem(42)};
	height: ${pxToRem(42)};
	border: 1px solid ${theme.color.colGray3};
	border-radius: 50%;
	justify-content: center;
`
const JTBCLogo = styled('a', {
	shouldForwardProp: (prop) => prop !== 'darkMode',
})(({ theme: thm }) => ({
	display: 'block',
	width: thm.pxToRem(118.2),
	height: thm.pxToRem(28.33),
	backgroundImage: 'url(https://nstatic.jtbc.co.kr/news-front/images/mo_blue_logo_2x.png)',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'left center',
	backgroundSize: `${thm.pxToRem(118.2)} ${thm.pxToRem(28.33)}`,
	[thm.breakpoints.up('md')]: {
		width: thm.pxToRem(160),
		height: thm.pxToRem(38.35),
		backgroundSize: `${thm.pxToRem(160)} ${thm.pxToRem(38.35)}`,
	},
}))
type Props = {
	resultMessage: string
}
export const MainTenance = ({ resultMessage }: Props) => {
	const { isApp } = useIsAppControl()
	const { data, isLoading } = useSNSListQuery()
	const nativeEvent = useRecoilValue(appInterface)
	const defaultMessage =
		!resultMessage || resultMessage.length === 0
			? `서비스 점검 중입니다.\n불편을 드려 죄송합니다.`
			: resultMessage

	const handleClose = () => {
		nativeEvent({
			key: 'appClose',
			value: null,
		})
	}

	return (
		<UIFlex
			position="fixed"
			top={0}
			left={0}
			right={0}
			bottom={0}
			bgcolor={theme.color.colGray1}
			zIndex={theme.zIndexes.top}
			alignItems="center"
			justifyContent="center"
			px={{ sm: pxToRem(36), md: pxToRem(87) }}
		>
			{!isLoading && (
				<UIFlex
					width={{
						xs: '100%',
						md: pxToRem(594),
						lg: pxToRem(768),
					}}
					px={{ md: pxToRem(48), lg: pxToRem(56) }}
					py={{ xs: pxToRem(48), md: pxToRem(42), lg: pxToRem(48) }}
					bgcolor={theme.color.colWhite}
					borderRadius={pxToRem(8)}
					sx={{
						flexDirection: { xs: 'column', md: 'row' },
						'&>*:not(:first-child)': {
							marginTop: {
								xs: pxToRem(24),
								md: 0,
							},
							marginLeft: {
								md: pxToRem(48),
							},
						},
					}}
					alignItems={{ xs: 'center', md: 'flex-start' }}
				>
					<Box
						sx={{
							flexShrink: 0,
							flexGrow: 1,
							maxWidth: {
								lg: `calc(50% - ${pxToRem(24)})`,
							},
						}}
					>
						<JTBCLogo href="/">
							<UIText readonly>JTBC</UIText>
						</JTBCLogo>
					</Box>
					<UIFlex
						alignItems={{
							xs: 'center',
							md: 'flex-start',
						}}
						sx={{
							flexShrink: 0,
							flexGrow: 1,
							maxWidth: {
								lg: `calc(50% - ${pxToRem(24)})`,
							},
						}}
					>
						<UIText
							fontSize={{ xs: pxToRem(18), md: pxToRem(20), lg: pxToRem(22) }}
							fontWeight={600}
							lineHeight={{ xs: pxToRem(26), md: pxToRem(28), lg: pxToRem(32) }}
							color={theme.color.colGray5}
							textAlign={{ xs: 'center', md: 'left' }}
							whiteSpace="pre-wrap"
						>
							{defaultMessage}
						</UIText>
						<UIFlex
							flexDirection="row"
							alignItems="center"
							justifyContent={{
								xs: 'center',
								md: 'flex-start',
							}}
							mt={{ xs: pxToRem(24), lg: pxToRem(32) }}
							sx={{
								'&>*:not(:first-child)': {
									marginLeft: {
										xs: pxToRem(12),
										md: pxToRem(20),
									},
								},
							}}
						>
							{data?.data?.map((sns) => (
								<SocialLink
									href={sns.linkUrlInfo.linkValue ?? ''}
									target="_blank"
									flexShrink={0}
									key={sns.snsIdx}
									overflow="hidden"
								>
									<UIImage
										src={sns.snsIconImgUrl}
										alt={sns.snsChannelName}
										style={{
											objectFit: 'contain',
											width: pxToRem(32),
											height: pxToRem(32),
										}}
									/>
									<UIText readonly>{sns.snsChannelName}</UIText>
								</SocialLink>
							))}
						</UIFlex>
						{isApp && (
							<UIButton
								variant="outlined"
								sx={{
									width: pxToRem(152),
									height: pxToRem(46),
									borderRadius: pxToRem(8),
									border: `1px solid ${color.colGray3}`,
									'&:hover': {
										border: `1px solid ${color.colGray3}`,
									},
									marginTop: {
										xs: pxToRem(24),
										md: pxToRem(32),
									},
								}}
								onClick={handleClose}
							>
								<UIText
									fontSize={pxToRem(16)}
									lineHeight={pxToRem(18)}
									color={color.colBlack}
									fontWeight={600}
								>
									확인
								</UIText>
							</UIButton>
						)}
					</UIFlex>
				</UIFlex>
			)}
		</UIFlex>
	)
}
