'use client'

import { MouseEvent, forwardRef, useCallback, useLayoutEffect, useState } from 'react'
import { ParsedUrlQueryInput } from 'querystring'
import { FrontUrlLinkResponseVo } from '@schemas/non-auth'
import UILinkRoot from './UILinkRoot'
import { UILinkBaseProps } from './UILinkBase'
import decodeNodeEntity from '@utils/decodeNodeEntity'
import { appInterface, useIsAppControl } from '@store/isApp'
import { useRecoilValue } from 'recoil'
import handleGAEvent, { GAProps } from '@utils/handleGAEvent'

export interface LinkProps extends FrontUrlLinkResponseVo {}

export interface UILinkProps extends Partial<UILinkBaseProps> {
	className?: string
	link?: LinkProps
	query?: ParsedUrlQueryInput
	ga?: GAProps
}

type LinkCode = FrontUrlLinkResponseVo['linkCode']
const pathToCode: {
	[key: string]: string
} = {
	ISSUE: 'issues',
	TOPIC: 'topic',
	CORNER: 'series',
	SERIES: 'series',
	PROGRAM: 'program',
	LIVE: 'live',
	ARTICLE: 'article',
	VIDEO_VIEW: 'video',
	SEARCH_WORD: 'search',
	URL: 'URL',
	JOURNALIST: 'journalist',
}
const UILink = forwardRef<HTMLAnchorElement, UILinkProps>(
	(
		{
			href,
			query,
			link,
			target,
			replace,
			overlay,
			startDecorator,
			endDecorator,
			level = 'body-md',
			variant = undefined,
			color = 'primary',
			textColor = undefined,
			underline = 'none',
			disabled = false,
			className,
			sx,
			children,
			as,
			ga,
			onClick,
			...props
		},
		ref,
	) => {
		const [text, setText] = useState(decodeNodeEntity(children))
		const { isApp } = useIsAppControl()
		const nativeEvent = useRecoilValue(appInterface)
		const getPathtoCode = (linkCode: LinkCode) => {
			return pathToCode[linkCode]
		}
		const url = useCallback(() => {
			const searchParam = new URLSearchParams(query as unknown as URLSearchParams)
			const searchParamString = searchParam.size > 0 ? `${searchParam.toString()}` : ''
			const { linkCode, linkValue } = link ?? {}
			if (linkCode) {
				const isURL = linkCode.includes('URL')
				if (isURL) {
					return `${linkValue ?? ''}`
				}
				if (linkCode === 'SEARCH_WORD') {
					searchParam.set('keyword', linkValue ?? '')
					return `/${getPathtoCode(linkCode)}?${searchParamString}`
				}
				return `/${getPathtoCode(linkCode)}${
					linkValue ? `/${linkValue}` : ''
				}?${searchParamString}`
			}
			const [pathName, pathParam] = ((href as string) ?? '/').split('?')
			const param =
				pathParam || searchParamString
					? `${pathParam ?? ''}${pathParam && searchParamString ? '&' : ''}${
							searchParamString ?? ''
					  }`
					: undefined
			return `${pathName}${param ? `?${param}` : ''}`
		}, [href, link, query])
		const handleClick = useCallback(
			(e: MouseEvent<HTMLAnchorElement>) => {
				onClick?.(e)
				if (ga) {
					handleGAEvent(ga)
				}
				const linkValue = link?.linkValue ?? href
				if (isApp && (link?.linkTarget === 'blank' || target === '_blank')) {
					e.preventDefault()
					e.stopPropagation()
					nativeEvent({
						key: 'outlink',
						value: {
							pageUrl: linkValue,
						},
					})
				}
			},
			[isApp, link, href, target, ga, onClick, nativeEvent],
		)
		useLayoutEffect(() => {
			setText(decodeNodeEntity(children))
		}, [children])
		return (
			<UILinkRoot
				className={className}
				target={link?.linkTarget ? `_${link.linkTarget}` : target ?? '_self'}
				rel={link?.linkTarget === 'blank' ? 'noopener noreferrer' : undefined}
				ref={ref}
				href={url()}
				replace={replace}
				overlay={overlay}
				startDecorator={startDecorator}
				endDecorator={endDecorator}
				level={level}
				variant={variant}
				color={color}
				textColor={textColor}
				underline={underline}
				disabled={disabled}
				ownerState={{ level, variant, color, textColor, underline, disabled, sx }}
				sx={sx}
				onClick={handleClick}
				suppressHydrationWarning
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
			>
				{text}
			</UILinkRoot>
		)
	},
)

export default UILink
