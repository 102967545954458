'use client'

import { styled } from '@mui/joy/styles'
import MuiBox from '@mui/joy/Box'

const UIFlexRoot = styled(MuiBox)(() => {
	const styles = {
		display: 'flex',
	}
	return styles
})

export default UIFlexRoot
