'use client'

import { styled, Theme } from '@mui/joy/styles'
import UILinkBase, { UILinkState } from './UILinkBase'

const UILinkRoot = styled(UILinkBase)(({
	theme,
	ownerState,
}: {
	theme?: Theme
	ownerState: UILinkState
}) => {
	const { level, variant, color, textColor, underline, disabled } = ownerState
	const { breakpoints, pxToRem } = theme ?? {}

	const styles = {
		color: 'inherit',
		lineHeight: 'normal',
		opacity: disabled ? 0.3 : 1,
		'&.Mui-disabled': {
			color: 'inherit',
		},
	}
	return styles
})

export default UILinkRoot
