import { CSSProperties, useEffect, useState } from 'react'
import UIImageContainer, { UIImageContainerProps } from './UIImageContainer'

interface UIImageProps extends Omit<UIImageContainerProps, 'children'> {
	src?: string
	alt?: string
	fallback?: string
	className?: string
	style?: CSSProperties
	notFallback?: boolean
}

const UIImage = ({
	src,
	alt = '',
	fallback,
	className,
	style,
	ratio,
	isContain,
	sx,
	onClick,
	notFallback,
	...props
}: UIImageProps) => {
	const [error, setError] = useState<boolean>(true)
	const imgSrc = ((url?: string) => {
		if (url && url.includes('.jtbc.co.kr') && url.includes('http://')) {
			return url.replace('http://', 'https://')
		}
		return url
	})(src)
	const ratioType = (r?: string | number) => {
		if (r && typeof r === 'string') {
			const split = r.split('/')
			return Number(split?.[0] ?? 0) < Number(split?.[1] ?? 0) ? 'vertical' : 'horizontal'
		}
		return 'horizontal'
	}
	const fallbackImage =
		fallback ??
		`https://nstatic.jtbc.co.kr/news-front/images/default-image-${ratioType(ratio)}.png`
	const onError = () => {
		setError(true)
	}
	useEffect(() => {
		if (imgSrc) {
			const preloadImage = new Image()
			preloadImage.src = imgSrc
			preloadImage.onload = () => {
				setError(false)
			}
		} else {
			setError(true)
		}
	}, [imgSrc])
	return (
		<UIImageContainer ratio={ratio} isContain={isContain} sx={sx} onClick={onClick} {...props}>
			<img
				src={imgSrc && notFallback ? imgSrc : !error ? imgSrc : fallbackImage}
				alt={alt}
				className={className}
				style={{
					...(ratio
						? {}
						: {
								width: '100%',
								height: 'auto',
						  }),
					backgroundColor: error || isContain ? '#eee' : 'transparent',
					...style,
				}}
				onError={onError}
			/>
		</UIImageContainer>
	)
}
export default UIImage
