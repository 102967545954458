'use client'

import { BoxProps as MuiBoxProps } from '@mui/joy/Box'
import { forwardRef } from 'react'
import UIFlexRoot from './UIFlexRoot'

export type ContentPosition = 'center' | 'end' | 'flex-end' | 'flex-start' | 'start' | 'stretch'

export interface UIFlexProps extends Omit<MuiBoxProps, 'align | justify | direction | wrap'> {
	align?: ContentPosition | 'self-end' | 'self-start' | 'baseline' | 'normal'
	justify?: ContentPosition | 'space-around' | 'space-between' | 'space-evenly'
	direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
	wrap?: 'nowrap' | 'wrap' | 'wrap-reverse'
}
const UIFlex = forwardRef<HTMLDivElement, UIFlexProps>(
	({ className, align, justify, wrap, direction = 'column', sx, children, ...props }, ref) => {
		return (
			<UIFlexRoot
				ref={ref}
				className={className}
				alignItems={align}
				justifyContent={justify}
				flexDirection={direction}
				flexWrap={wrap}
				sx={sx}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
			>
				{children}
			</UIFlexRoot>
		)
	},
)

export default UIFlex
