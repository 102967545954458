import { AspectRatio } from '@mui/joy'
import { SxProps } from '@mui/joy/styles/types'
import { ReactNode } from 'react'

export interface UIImageContainerProps {
	children: ReactNode
	ratio?: string | number
	isContain?: boolean
	sx?: SxProps
	onClick?: () => void
}

const UIImageContainer = ({
	children,
	ratio,
	isContain,
	sx,
	onClick,
	...props
}: UIImageContainerProps) => {
	return ratio ? (
		<AspectRatio
			{...props}
			ratio={ratio}
			objectFit={isContain ? 'contain' : 'cover'}
			sx={{
				'& .MuiAspectRatio-content': {
					background: 'transparent',
					img: {
						objectPosition: isContain ? 'center' : '50% 0',
					},
				},
				...sx,
			}}
			onClick={onClick}
		>
			{children}
		</AspectRatio>
	) : (
		children
	)
}

export default UIImageContainer
